/*
@File: Tuam Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader Area CSS
** - Navbar Area CSS
** - Main Banner Area CSS
** - About Area CSS
** - Video Area CSS
** - Services Area CSS
** - Services Details Area CSS
** - Partner Area CSS
** - Gallery Area CSS
** - Why Choose Us Area CSS
** - Benefits Area CSS
** - Pricing Area CSS
** - Join Area CSS
** - Feedback Area CSS
** - Our Mission Area CSS
** - Team Area CSS
** - Event Area CSS
** - Event Details Area CSS
** - Place Area CSS
** - Floor Plans CSS
** - Blog Area CSS
** - Blog Details Area CSS
** - Page Title Area CSS
** - Pagination Area CSS
** - Sidebar Widget Area CSS
** - Subscribe Area CSS
** - 404 Error Area CSS
** - Contact Area CSS
** - Footer Area CSS
** - Go Top CSS
*/

/*================================================
Default CSS
=================================================*/

$font-family: 'IBM Plex Sans', sans-serif;
//$main-color: #ff5d22;
$main-color: #06487e;
$black-color: #524552;
// $black-color: #b6b092;
$white-color: #ffffff;
$paragraph-color: #524552;
// $paragraph-color: #b6b092;
// $paragraph-color: #6a6654;
$font-size: 16px;
$transition: 0.5s;

body {
  color: $black-color;
  padding: 0;
  margin: 0;
  font: {
    family: $font-family;
    size: $font-size;
  }
}
.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}
img {
  max-width: 100%;
  height: auto;
}
a {
  color: $black-color;
  transition: $transition;
  text-decoration: none;
  outline: 0 !important;

  &:hover {
    color: $main-color;
    text-decoration: none;
  }
}
p {
  line-height: 1.8;
  color: $paragraph-color;
  margin-bottom: 15px;
  font: {
    weight: 300;
    size: $font-size;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.bg-f8f8f8 {
  background-color: #f8f8f8;
}
button {
  outline: 0 !important;
}
.ptb-100 {
  padding: {
    top: 100px;
    bottom: 100px;
  }
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pt-100 {
  padding-top: 100px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black-color;
}
.section-bg-white {
  background-color: #fff;
  .bg-f8f8f8 {
    background-color: #fff;
  }
}
.gray-bg {
  background-color: #f8f8f8;
  .feedback-area {
    background-color: #f8f8f8;
  }
}
/*section-title*/
.section-title {
  text-align: center;
  max-width: 750px;
  margin: {
    left: auto;
    right: auto;
    bottom: 50px;
    top: -6px;
  }
  .sub-title {
    color: $main-color;
    font-size: 18px;
    display: block;
    margin-bottom: 8px;
  }
  h2 {
    margin-bottom: 0;
    font: {
      size: 42px;
      weight: 700;
    }
  }
  p {
    font-size: 18px;
    max-width: 650px;
    margin: {
      left: auto;
      right: auto;
      bottom: 0;
      top: 10px;
    }
  }
}
/*default-btn*/
.default-btn {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: $white-color;
  text-transform: uppercase;
  background-color: $main-color;
  transition: $transition;
  border-radius: 5px;
  font: {
    weight: 600;
    size: 15px;
  }
  padding: {
    left: 30px;
    right: 30px;
    top: 14px;
    bottom: 14px;
  }
  span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: $black-color;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
    border-radius: 5px;
  }
  &:hover {
    color: $white-color;

    span {
      width: 225%;
      height: 562.5px;
    }
  }
}
/*================================================
Preloader Area CSS
=================================================*/
.preloader {
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background-color: $main-color;
  text-align: center;

  #global {
    position: absolute;
    width: 80px;
    height: 70px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 47%;
    transform: translateY(-47%);

    .mask {
      position: absolute;
      border-radius: 2px;
      overflow: hidden;
      perspective: 1000;
      backface-visibility: hidden;
    }
    .plane {
      background: $white-color;
      width: 400%;
      height: 100%;
      position: absolute;
      transform: translate3d(0px, 0, 0);
      z-index: 100;
      perspective: 1000;
      backface-visibility: hidden;
    }
    #top {
      .plane {
        z-index: 2000;
        animation: trans1 1.3s ease-in infinite 0s backwards;
      }
    }
    #middle {
      .plane {
        transform: translate3d(0px, 0, 0);
        background: #bbbbbb;
        animation: trans2 1.3s linear infinite 0.3s backwards;
      }
    }
    #bottom {
      .plane {
        z-index: 2000;
        animation: trans3 1.3s ease-out infinite 0.7s backwards;
      }
    }
    #top {
      width: 53px;
      height: 20px;
      left: 20px;
      transform: skew(-15deg, 0);
      z-index: 100;
    }
    #middle {
      width: 33px;
      height: 20px;
      left: 20px;
      top: 15px;
      transform: skew(-15deg, 40deg);
    }
    #bottom {
      width: 53px;
      height: 20px;
      top: 30px;
      transform: skew(-15deg, 0);
    }
    p {
      color: $white-color;
      position: absolute;
      left: -3px;
      top: 55px;
      text-align: center;
      font: {
        size: 10px;
        family: Arial;
      }
    }
  }
}
.preloader-deactivate {
  display: none;
}
@keyframes trans1 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-250px, 0, 0);
  }
}
@keyframes trans2 {
  from {
    transform: translate3d(-160px, 0, 0);
  }
  to {
    transform: translate3d(53px, 0, 0);
  }
}
@keyframes trans3 {
  from {
    transform: translate3d(53px, 0, 0);
  }
  to {
    transform: translate3d(-220px, 0, 0);
  }
}

/*================================================
Navbar Area CSS
=================================================*/
.tuam-nav {
  background-color: transparent;

  .navbar {
    background-color: $white-color;
    box-shadow: -10px 10px rgba(255, 255, 255, 0.6);
    transition: $transition;
    padding: {
      right: 25px;
      top: 0;
      left: 25px;
      bottom: 0;
    }
    .navbar-brand {
      padding: {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    ul {
      padding-left: 0;
      list-style-type: none;
      margin-bottom: 0;
    }
    .navbar-nav {
      margin-left: auto;

      .nav-item {
        position: relative;
        padding: 0;

        a {
          font: {
            size: 15px;
          }
          color: $black-color;
          text-transform: uppercase;
          transition: $transition;
          position: relative;
          padding: {
            left: 0;
            right: 0;
            top: 30px;
            bottom: 30px;
          }
          margin: {
            left: 13px;
            right: 13px;
          }
          &:hover,
          &:focus,
          &.active {
            color: $main-color;
          }
          i {
            font-size: 11px;
            position: relative;
            left: 2px;
          }
        }
        &:last-child {
          a {
            margin-right: 0;
          }
        }
        &:first-child {
          a {
            margin-left: 0;
          }
        }
        &:hover,
        &.active {
          a {
            color: $main-color;
          }
        }
        .dropdown-menu {
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
          background: $white-color;
          position: absolute;
          border: none;
          top: 80px;
          left: 0;
          width: 250px;
          z-index: 99;
          display: block;
          opacity: 0;
          visibility: hidden;
          border-radius: 0;
          transition: all 0.3s ease-in-out;
          margin-top: 20px;
          padding: {
            top: 20px;
            left: 5px;
            right: 5px;
            bottom: 20px;
          }
          li {
            padding: {
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
            }
            a {
              text-transform: capitalize;
              padding: 8px 15px;
              margin: 0;
              position: relative;
              color: $black-color;
              font: {
                size: 15.5px;
                weight: 400;
              }
              &::before {
                content: '';
                position: absolute;
                left: -5px;
                top: 50%;
                width: 0;
                transition: $transition;
                height: 1px;
                background-color: $main-color;
                transform: translateY(-50%);
              }
              &:hover,
              &:focus,
              &.active {
                color: $main-color;
                padding-left: 26px;

                &::before {
                  width: 20px;
                }
              }
            }
            .dropdown-menu {
              left: -250px;
              top: 0;
              opacity: 0;
              visibility: hidden;

              li {
                a {
                  color: $black-color;

                  &:hover,
                  &:focus,
                  &.active {
                    color: $main-color;
                  }
                }
                .dropdown-menu {
                  left: 220px;
                  top: 0;
                  opacity: 0;
                  visibility: hidden;

                  li {
                    a {
                      color: $black-color;

                      &:hover,
                      &:focus,
                      &.active {
                        color: $main-color;
                      }
                    }
                    .dropdown-menu {
                      left: -250px;
                      top: 0;
                      opacity: 0;
                      visibility: hidden;

                      li {
                        a {
                          color: $black-color;

                          &:hover,
                          &:focus,
                          &.active {
                            color: $main-color;
                          }
                        }
                        .dropdown-menu {
                          left: -250px;
                          top: 0;
                          opacity: 0;
                          visibility: hidden;

                          li {
                            a {
                              color: $black-color;

                              &:hover,
                              &:focus,
                              &.active {
                                color: $main-color;
                              }
                            }
                            .dropdown-menu {
                              left: -250px;
                              top: 0;
                              opacity: 0;
                              visibility: hidden;

                              li {
                                a {
                                  color: $black-color;

                                  &:hover,
                                  &:focus,
                                  &.active {
                                    color: $main-color;
                                  }
                                }
                                .dropdown-menu {
                                  left: -250px;
                                  top: 0;
                                  opacity: 0;
                                  visibility: hidden;

                                  li {
                                    a {
                                      color: $black-color;

                                      &:hover,
                                      &:focus,
                                      &.active {
                                        color: $main-color;
                                      }
                                    }
                                  }
                                }
                                &.active {
                                  a {
                                    color: $main-color;
                                  }
                                }
                                &:hover {
                                  .dropdown-menu {
                                    opacity: 1;
                                    visibility: visible;
                                    top: -15px;
                                  }
                                }
                              }
                            }
                            &.active {
                              a {
                                color: $main-color;
                              }
                            }
                            &:hover {
                              .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                top: -15px;
                              }
                            }
                          }
                        }
                        &.active {
                          a {
                            color: $main-color;
                          }
                        }
                        &:hover {
                          .dropdown-menu {
                            opacity: 1;
                            visibility: visible;
                            top: -15px;
                          }
                        }
                      }
                    }
                    &.active {
                      a {
                        color: $main-color;
                      }
                    }
                    &:hover {
                      .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        top: -15px;
                      }
                    }
                  }
                }
                &.active {
                  a {
                    color: $main-color;
                  }
                }
                &:hover {
                  .dropdown-menu {
                    opacity: 1;
                    visibility: visible;
                    top: -15px;
                  }
                }
              }
            }
            &.active {
              a {
                color: $main-color;
              }
            }
            &:hover {
              .dropdown-menu {
                opacity: 1;
                visibility: visible;
                top: -15px;
              }
            }
          }
        }
        &:hover {
          .dropdown-menu {
            opacity: 1;
            visibility: visible;
            top: 100%;
            margin-top: 0;
          }
        }
      }
    }
    .others-option {
      margin-left: 25px;

      .call-us {
        padding-left: 50px;
        position: relative;
        text-transform: uppercase;
        font: {
          size: 14px;
          weight: 300;
        }
        .icon {
          width: 40px;
          height: 40px;
          line-height: 40px;
          border-radius: 50%;
          color: $main-color;
          font-size: 20px;
          background-color: #f1f4f4;
          text-align: center;
          position: absolute;
          left: 0;
          top: 50%;
          transition: $transition;
          transform: translateY(-50%);
        }
        .number {
          display: block;
          color: $main-color;
          font-weight: 600;
        }
        &:hover {
          .icon {
            background-color: $main-color;
            color: $white-color;
          }
        }
      }
    }
  }
}
.navbar-area {
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  height: auto;
  z-index: 999;
  background-color: transparent;
  transition: $transition;

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    background-color: $white-color !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;

    .tuam-nav {
      .navbar {
        box-shadow: unset;
        padding: {
          left: 0;
          right: 0;
        }
      }
    }
  }
  &.navbar-style-two {
    top: 0;
    padding: {
      left: 40px;
      right: 40px;
    }
    .tuam-nav {
      background-color: transparent;

      .navbar {
        background-color: transparent;
        box-shadow: unset;
        padding: {
          right: 0;
          top: 0;
          left: 0;
          bottom: 0;
        }
        .navbar-brand {
          .logo {
            display: none;
          }
        }
        .navbar-nav {
          margin: {
            left: auto;
            right: auto;
          }
          .nav-item {
            a {
              color: $white-color;
              position: relative;
              transition: $transition;

              &:hover,
              &:focus,
              &.active {
                color: $main-color;
              }
            }
            &:hover,
            &.active {
              a {
                color: $main-color;
              }
            }
            .dropdown-menu {
              li {
                a {
                  color: $black-color;
                  position: relative;
                  transition: $transition;

                  &:hover,
                  &:focus,
                  &.active {
                    color: $main-color;
                  }
                }
                .dropdown-menu {
                  li {
                    a {
                      color: $black-color;

                      &:hover,
                      &:focus,
                      &.active {
                        color: $main-color;
                      }
                    }
                    .dropdown-menu {
                      li {
                        a {
                          color: $black-color;

                          &:hover,
                          &:focus,
                          &.active {
                            color: $main-color;
                          }
                        }
                        .dropdown-menu {
                          li {
                            a {
                              color: $black-color;

                              &:hover,
                              &:focus,
                              &.active {
                                color: $main-color;
                              }
                            }
                            .dropdown-menu {
                              li {
                                a {
                                  color: $black-color;

                                  &:hover,
                                  &:focus,
                                  &.active {
                                    color: $main-color;
                                  }
                                }
                                .dropdown-menu {
                                  li {
                                    a {
                                      color: $black-color;

                                      &:hover,
                                      &:focus,
                                      &.active {
                                        color: $main-color;
                                      }
                                    }
                                    .dropdown-menu {
                                      li {
                                        a {
                                          color: $black-color;

                                          &:hover,
                                          &:focus,
                                          &.active {
                                            color: $main-color;
                                          }
                                        }
                                      }
                                    }
                                    &.active {
                                      a {
                                        color: $main-color;
                                      }
                                    }
                                  }
                                }
                                &.active {
                                  a {
                                    color: $main-color;
                                  }
                                }
                              }
                            }
                            &.active {
                              a {
                                color: $main-color;
                              }
                            }
                          }
                        }
                        &.active {
                          a {
                            color: $main-color;
                          }
                        }
                      }
                    }
                    &.active {
                      a {
                        color: $main-color;
                      }
                    }
                  }
                }
                &.active {
                  a {
                    color: $main-color;
                  }
                }
              }
            }
          }
        }
        .others-option {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .call-us {
            display: inline-block;
            color: $white-color;
            font: {
              size: 14px;
              weight: 300;
            }
          }
          .default-btn {
            margin-left: 25px;
            background-color: transparent;
            border: 1px solid $white-color;
            padding: {
              top: 12px;
              bottom: 12px;
            }
            span {
              background-color: $main-color;
            }
            &:hover {
              color: $white-color;
              border-color: $main-color;
            }
          }
        }
      }
    }
    &.is-sticky {
      box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.2);
      background-color: $black-color !important;

      .tuam-nav {
        .navbar {
          background-color: $black-color;
        }
      }
    }
  }
}

/* Navbar toggler */
.navbar-toggler {
  border: none;
  border-radius: 0;
  padding: 0;

  &:focus {
    box-shadow: none;
  }

  .icon-bar {
    width: 35px;
    transition: all 0.3s;
    background: $main-color;
    height: 4px;
    display: block;
    border-radius: 3px;
  }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
    left: 4px;
    position: relative;
  }
  .middle-bar {
    opacity: 0;
    margin: 5px 0;
  }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
    left: 4px;
    position: relative;
  }
  &.collapsed {
    .top-bar {
      transform: rotate(0);
      left: 0;
    }
    .middle-bar {
      opacity: 1;
    }
    .bottom-bar {
      transform: rotate(0);
      left: 0;
    }
  }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {
  .navbar-area {
    background-color: $white-color;
    position: absolute;
    top: 0;
    padding: {
      top: 15px;
      bottom: 15px;
    }
    &.navbar-style-two {
      padding: {
        left: 0;
        right: 0;
      }
      &.is-sticky {
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: $white-color !important;
      }
    }
  }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
  height: 925px;
  position: relative;
  z-index: 2;
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #1a0602;
    opacity: 0.6;
  }
}
//
.banner-section {
  height: 100vh;
  position: relative;
  z-index: 1;
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #1a0602;
    opacity: 0.6;
  }
  .main-banner-content {
    margin-top: 30px;
    max-width: 940px;
  }
}
.main-banner-content {
  text-align: center;
  max-width: 900px;
  position: relative;
  z-index: 3;
  margin: {
    left: auto;
    right: auto;
    top: -30px;
  }
  .sub-title {
    text-transform: uppercase;
    display: block;
    color: $white-color;
    letter-spacing: 3px;
    margin-bottom: 8px;
    font: {
      size: 16px;
    }
  }
  h1 {
    color: $white-color;
    margin-bottom: 0;
    font: {
      size: 65px;
      weight: 700;
    }
  }
  .btn-box {
    margin-top: 40px;

    .default-btn {
      span {
        background-color: $white-color;
      }
      &:hover {
        color: $main-color;
      }
    }
    .default-btn,
    .optional-btn {
      margin: {
        left: 10px;
        right: 10px;
      }
    }
    .optional-btn {
      color: $white-color !important;
      border-color: $white-color;

      &:hover {
        background-color: $white-color;
        color: $main-color !important;
        border-color: $white-color;
      }
    }
  }
  p {
    color: $white-color;
    font-size: 18px;
    margin: {
      bottom: 0;
      top: 30px;
    }
  }
  form {
    position: relative;
    max-width: 800px;
    margin: {
      left: auto;
      top: 15px;
      right: auto;
    }
    .form-group {
      position: relative;

      .input-location {
        display: block;
        width: 75%;
        color: $black-color;
        outline: 0;
        border: none;
        height: 55px;
        padding-left: 65px;
        border-radius: 5px;
        font: {
          family: $font-family;
          size: $font-size;
          weight: 300;
        }
        &::placeholder {
          color: #524552;
        }
      }
      i {
        color: $main-color;
        font-size: 25px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 25px;
      }
    }
    .default-btn {
      height: 55px;
      line-height: 55px;
      position: absolute;
      top: 0;
      right: 0;
      padding: {
        top: 0;
        bottom: 0;
      }
      i {
        margin-left: 2px;
      }
      span {
        background-color: $white-color;
      }
      &:hover {
        color: $main-color;
      }
    }
  }
}
.home-area {
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 350px;
    z-index: 5;
    background: {
      image: url(../images/banner-shape.png);
      position: center center;
      size: cover;
      repeat: no-repeat;
    }
  }
}
.home-slides {
  .swiper-button-next,
  .swiper-button-prev {
    &::after {
      color: $main-color;
    }
  }
}
.home-section {
  position: relative;
  z-index: 1;

  .banner-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: 1;

    .banner-video {
      text-align: center;

      .video-btn {
        width: 65px;
        height: 65px;
        line-height: 65px;
        color: $main-color;
        cursor: pointer;
        border-radius: 50%;
        z-index: 1;
        position: relative;
        background-color: $white-color;
        display: inline-block;
        font-size: 25px;

        &:hover {
          background-color: $main-color;
          color: $white-color;
        }
        &::after,
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          z-index: -1;
          bottom: 0;
          left: 0;
          border-radius: 50%;
          border: 1px solid $white-color;
        }
        &::before {
          animation: ripple 2s linear infinite;
        }
        &::after {
          animation: ripple 2s linear 1s infinite;
        }
      }
      span {
        display: inline-block;
        text-transform: uppercase;
        color: $white-color;
        margin-left: 25px;
      }
    }
    .banner-contact-info {
      padding: 35px;
      border-radius: 5px 0 0 0;
      background-color: $main-color;

      ul {
        padding-left: 0;
        list-style-type: none;
        // max-width: 770px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
          bottom: 0;
          // right: -15px;
          // left: -15px;
        }
        li {
          position: relative;
          color: $white-color;
          padding-left: 65px;
          font-size: 14px;
          -ms-flex: 0 0 33.333333%;
          flex: 0 0 33.333333%;
          max-width: 33.333333%;
          padding: {
            left: 80px;
            right: 15px;
          }
          span {
            display: block;
            margin-bottom: 5px;
            font: {
              size: 16px;
              weight: 600;
            }
          }
          i {
            display: inline-block;
            position: absolute;
            left: 15px;
            top: 0;
            width: 50px;
            height: 50px;
            line-height: 50px;
            transition: $transition;
            border-radius: 50%;
            color: $white-color;
            background-color: #bc5435;
            text-align: center;
            font-size: 20px;
          }
          a {
            display: block;
            color: $white-color;
          }
          &:last-child {
            margin-right: 0;
          }
          // &:hover {
          //   i {
          //     background-color: $white-color;
          //     color: $main-color;
          //   }
          // }
        }
      }
    }
  }
}

/*================================================
Services Area CSS
=================================================*/
.single-services-box {
  position: relative;
  margin-bottom: 30px;
  text-align: center;
  z-index: 1;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  padding: 30px;
  transition: $transition;

  .icon {
    font-size: 65px;
    transition: $transition;
    position: relative;
    z-index: 1;
    margin: {
      top: -15px;
      bottom: 10px;
    }
    .icon-bg {
      img {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        width: auto !important;
        display: inline-block !important;
        z-index: -1;
      }
    }
  }
  h3 {
    margin-bottom: 10px;
    position: relative;
    padding-bottom: 13px;
    transition: $transition;
    font: {
      size: 24px;
      weight: 600;
    }
    a {
      display: inline-block;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 0 auto;
      background-color: #e9e9e9;
      height: 2px;
      width: 60px;
      transition: $transition;
    }
  }
  p {
    transition: $transition;
    margin: {
      top: 0;
      bottom: 0;
    }
  }
  .read-more-btn {
    display: inline-block;
    margin-top: 15px;
    color: $main-color;
    font: {
      size: $font-size;
      weight: 600;
    }
    &:hover {
      letter-spacing: 0.5px;
    }
  }
  .box-shape {
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: auto !important;
      transition: $transition;
      display: inline-block !important;

      &:nth-child(2) {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
.services-box {
  margin-bottom: 30px;
  text-align: center;
  border-radius: 5px;
  background-color: $black-color;

  img {
    border-radius: 5px;
  }
  .services-content {
    padding: 30px;

    h3 {
      margin-bottom: 15px;
      position: relative;
      padding-bottom: 13px;
      color: $white-color;
      font: {
        size: 24px;
        weight: 600;
      }
      a {
        display: inline-block;
        color: $white-color;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
        background-color: $white-color;
        height: 2px;
        width: 60px;
        transition: $transition;
      }
    }
    p {
      color: $white-color;
      margin: {
        top: 0;
        bottom: 0;
      }
    }
  }
}
.services-slides {
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    &::after {
      color: $main-color;
      font-size: 25px;
    }
  }
  .swiper-pagination {
    position: initial;
    line-height: 1;
    .swiper-pagination-bullet {
      width: 15px;
      height: 4px;
      border-radius: 0;
      background-color: $black-color;
      transition: $transition;

      &.swiper-pagination-bullet-active {
        background-color: $main-color;
      }
    }
  }
}
.main-services-slides {
  .swiper-pagination {
    position: initial;
    line-height: 1;
    .swiper-pagination-bullet {
      width: 15px;
      height: 4px;
      border-radius: 0;
      background-color: $black-color;
      transition: $transition;

      &.swiper-pagination-bullet-active {
        background-color: $main-color;
      }
    }
  }
}
.services-boxes-area {
  margin-top: -180px;
  position: relative;
  z-index: 3;
}
.single-box {
  text-align: center;
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  img {
    border-radius: 5px;
    transition: $transition;
  }
  .content {
    background-color: $main-color;
    padding: 65px 35px 50px;
    position: absolute;
    left: 0;
    transition: $transition;
    height: auto;
    width: 100%;
    bottom: 0;
    border-radius: 100% 100% 5px 5px;

    h3 {
      margin-bottom: 0;
      position: relative;
      padding-bottom: 15px;
      transition: $transition;
      color: $white-color;
      font: {
        size: 24px;
        weight: 600;
      }
      a {
        display: inline-block;
        color: $white-color;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
        background-color: $white-color;
        height: 2px;
        width: 60px;
        transition: $transition;
      }
    }
  }
  .hover-content {
    background-color: $main-color;
    padding: 65px 35px 50px;
    position: absolute;
    left: 0;
    height: auto;
    width: 100%;
    bottom: -138px;
    border-radius: 100% 100% 5px 5px;
    transition: $transition;
    opacity: 0;
    visibility: hidden;

    h3 {
      margin-bottom: 15px;
      position: relative;
      padding-bottom: 15px;
      transition: $transition;
      color: $white-color;
      font: {
        size: 24px;
        weight: 600;
      }
      a {
        display: inline-block;
        color: $white-color;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
        background-color: $white-color;
        height: 2px;
        width: 60px;
        transition: $transition;
      }
    }
    p {
      transition: $transition;
      color: $white-color;
      margin: {
        top: 0;
        bottom: 0;
      }
    }
    .read-more-btn {
      display: inline-block;
      margin-top: 15px;
      color: $white-color;
      position: relative;
      font: {
        size: $font-size;
        weight: 600;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $black-color;
      }
    }
  }
  &:hover {
    .content {
      opacity: 0;
      visibility: hidden;
    }
    .hover-content {
      opacity: 1;
      visibility: visible;
      bottom: 0;
    }
    img {
      transform: scale(1.3) rotate(4deg);
    }
  }
}

/*================================================
Services Details Area CSS
=================================================*/
.services-details-overview {
  -ms-flex-align: center !important;
  align-items: center !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: {
    right: -25px;
    left: -25px;
    bottom: 60px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .services-details-desc {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: {
      right: 25px;
      left: 25px;
    }
    h3 {
      margin-bottom: 15px;
      font: {
        size: 24px;
        weight: 600;
      }
    }
    .features-text {
      margin-top: 25px;

      h4 {
        margin-bottom: 15px;
        font: {
          size: 17px;
          weight: 600;
        }
        i {
          font-size: 15px;
          margin-right: 4px;
          color: $main-color;
        }
      }
    }
    .services-details-accordion {
      margin-top: 25px;

      .accordion {
        list-style-type: none;
        padding-left: 0;
        margin: {
          bottom: 0;
        }
        .accordion-item {
          display: block;
          box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
          background: $white-color;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
          &:nth-child(2),
          &:nth-child(4),
          &:nth-child(6),
          &:nth-child(8) {
            .accordion-title {
              i {
                background: $black-color;
              }
            }
          }
        }
        .accordion-title {
          padding: 15px 20px 15px 51px;
          color: $black-color;
          position: relative;
          border-bottom: 1px solid transparent;
          margin-bottom: -1px;
          display: inline-block;
          font: {
            size: $font-size;
            weight: 600;
          }
          i {
            position: absolute;
            left: 0;
            top: 0;
            width: 40px;
            text-align: center;
            height: 100%;
            background: $main-color;
            color: $white-color;

            &::before {
              position: absolute;
              left: 0;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              font-size: 13px;
            }
          }
          &.active {
            border-bottom-color: #eeeeee;

            i {
              &::before {
                content: '\f068';
              }
            }
          }
        }
        .accordion-content {
          display: none;
          position: relative;
          padding: 15px 15px;

          &.show {
            display: block;
          }
        }
      }
    }
  }
  .services-details-image {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: {
      right: 15px;
      left: 15px;
    }
  }
}

/*================================================
Gallery Area CSS
=================================================*/
.shorting-menu {
  text-align: center;
  margin-bottom: 40px;

  .filter {
    border: none;
    background-color: #f5f5f5;
    border-radius: 5px;
    transition: $transition;
    color: $black-color;
    padding: 10px 20px;
    margin: {
      left: 2px;
      right: 2px;
    }
    font: {
      size: 15px;
      weight: 600;
    }
    &:hover,
    &.programs-filter-btn-active {
      background-color: $main-color;
      color: $white-color;
    }
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
.gallery-area {
  .container-fluid {
    padding: 0;
    .row {
      margin: 0;
    }
  }
}
.single-gallery-box {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  text-align: center;
  position: relative;
  padding: 0;

  img {
    width: 100%;
  }

  &::before {
    width: 100%;
    content: '';
    position: absolute;
    height: 100%;
    background-color: $main-color;
    left: 0;
    top: 0;
    transition: $transition;
    opacity: 0;
    visibility: hidden;
  }
  .gallery-btn {
    position: absolute;
    cursor: pointer;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 1;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: $white-color;
    color: $black-color;
    font-size: 18px;
    margin-top: 15px;
    opacity: 0;
    visibility: hidden;

    &:hover {
      color: $white-color;
      background-color: $black-color;
    }
  }
  &:hover {
    &::before {
      opacity: 0.85;
      visibility: visible;
    }
    .gallery-btn {
      opacity: 1;
      visibility: visible;
      margin-top: 0;
      cursor: pointer;
    }
  }
}

/*================================================
Floor Plans CSS
=================================================*/
.floor-plans-area {
  background: {
    image: url(../images/background.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
}
.tabs_item {
  display: none;

  &:first-child {
    display: block;
  }
}
.tab {
  .tabs {
    text-align: center;
    padding: 0;
    margin: 0 0 40px;
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin: {
      left: -15px;
      right: -15px;
    }

    li {
      display: block;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      position: relative;
      z-index: 1;
      cursor: pointer;

      padding: {
        right: 15px;
        left: 15px;
      }
      &::before {
        width: 30px;
        height: 30px;
        left: 0;
        bottom: -8px;
        position: absolute;
        background: $main-color;
        content: '';
        transform: rotate(45deg);
        right: 0;
        margin: 0 auto;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: $transition;
      }

      background-color: $white-color;
      padding: 18px 40px;
      color: $black-color;
      text-transform: uppercase;

      font: {
        weight: 600;
        size: $font-size;
      }
      &:hover {
        background-color: $main-color;
        color: $white-color;
      }

      &.current {
        background-color: $main-color;
        color: #fff;

        &::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .tab_content {
    background-color: $white-color;
    padding: 35px;

    .tabs_item {

      .desktop-slider {
        //background-color: green;

        .tabs_item_content {
          //background-color: blue;
          display: flex;
          flex-direction: column;
          height: 100%;

          .carousel {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }


      .tabs_item_content {
        h3 {
          margin-bottom: 12px;
          font: {
            size: 24px;
            weight: 600;
          }
        }
        .features-list {
          padding-left: 0;
          list-style-type: none;
          margin: {
            top: 25px;
            bottom: 0;
          }
          li {
            margin-bottom: 12px;
            color: $paragraph-color;
            font-weight: 500;
            overflow: hidden;
            padding-bottom: 12px;
            border-bottom: 1px solid #eeeeee;

            span {
              float: right;
              color: $black-color;
              font-weight: 600;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .default-btn {
          margin-top: 30px;
        }
      }
    }
  }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  height: 450px;
  position: relative;
  z-index: 1;
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #1a0602;
    opacity: 0.6;
  }
}
.page-title-content {
  text-align: center;
  margin-top: 140px;

  h2 {
    margin-bottom: 0;
    color: $white-color;
    font: {
      size: 42px;
      weight: 700;
    }
  }
  ul {
    padding-left: 0;
    list-style-type: none;
    margin: {
      top: 12px;
      bottom: 0;
    }
    li {
      color: $white-color;
      display: inline-block;
      position: relative;
      font: {
        weight: 400;
        size: 17px;
      }
      margin: {
        left: 15px;
        right: 15px;
      }
      a {
        display: inline-block;
        color: $white-color;
      }
      &::before {
        content: '';
        position: absolute;
        right: -18px;
        top: 11px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $main-color;
      }
      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
  height: 100vh;
}
.error-content {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;

  h3 {
    font: {
      size: 40px;
      weight: 700;
    }
    margin: {
      top: 45px;
      bottom: 15px;
    }
  }
  p {
    max-width: 520px;
    margin: 0 auto 20px;
  }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  background-color: #2e282e;
  padding-top: 70px;
}
.single-footer-widget {
  margin-bottom: 30px;

  h3 {
    margin-bottom: 25px;
    color: $white-color;
    position: relative;
    padding-bottom: 8px;
    border-bottom: 1px solid #493f49;
    font: {
      size: 24px;
      weight: 600;
    }
    &::before {
      bottom: -1px;
      left: 0;
      width: 55px;
      height: 1px;
      background-color: $main-color;
      content: '';
      position: absolute;
    }
  }
  .logo {
    a {
      display: inline-block;
    }
    p {
      color: #ebe7e7;
      margin: {
        bottom: 0;
        top: 20px;
      }
    }
  }
  .social {
    padding-left: 0;
    list-style-type: none;
    margin: {
      bottom: 0;
      top: 20px;
    }
    li {
      display: inline-block;
      margin-right: 8px;

      a {
        display: inline-block;
        background-color: #493f49;
        color: $main-color;
        width: 32px;
        font-size: 12px;
        height: 32px;
        line-height: 32px;
        border-radius: 50%;
        text-align: center;

        i {
          &.flaticon-facebook {
            display: inline-block;
            padding-left: 4px;
          }
          &.flaticon-twitter {
            display: inline-block;
            position: relative;
            top: 2px;
          }
        }
        &:hover {
          background-color: $main-color;
          color: $white-color;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .footer-quick-links {
    padding-left: 0;
    list-style-type: none;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: {
      right: -15px;
      left: -15px;
      bottom: 0;
      top: -12px;
    }
    li {
      -ms-flex: 0 0 50%;
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;
      padding: {
        left: 15px;
        right: 15px;
        top: 12px;
      }
      a {
        display: inline-block;
        color: #ebe7e7;
        text-transform: uppercase;
        font-size: 14px;

        &:hover {
          color: $main-color;
        }
      }
    }
  }
  .footer-contact-info {
    padding-left: 0;
    list-style-type: none;
    margin: {
      bottom: 0;
    }
    li {
      color: #ebe7e7;
      font-size: 15px;
      margin-bottom: 12px;

      span {
        display: inline-block;
        font-weight: 700;
        color: $white-color;
      }
      a {
        display: inline-block;
        color: $white-color;
        position: relative;

        &::before {
          width: 100%;
          height: 1px;
          background-color: $main-color;
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          opacity: 0;
          visibility: hidden;
          transition: $transition;
        }
        &:hover {
          color: $main-color;
        }
      }
      &:last-child {
        margin-bottom: 0;

        a {
          &::before {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
.copyright-area {
  margin-top: 40px;
  border-top: 1px solid #493f49;
  padding: {
    top: 25px;
    bottom: 25px;
  }
  p {
    color: #ebe7e7;
    font-size: 15px;

    a {
      color: $white-color;
      display: inline-block;
      font-weight: 600;

      &:hover {
        color: $main-color;
      }
    }
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    text-align: right;

    li {
      display: inline-block;
      color: #ebe7e7;
      font-size: 15px;
      position: relative;
      margin: {
        left: 15px;
        right: 10px;
      }
      a {
        display: inline-block;
        color: #ebe7e7;

        &:hover {
          color: $main-color;
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 5px;
        right: -13px;
        width: 1px;
        height: 14px;
        background-color: $white-color;
      }
      &:last-child {
        margin-right: 0;

        &::before {
          display: none;
        }
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 15px;
  right: 15px;
  color: $white-color;
  background-color: $black-color;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 42px;
  line-height: 42px;
  transition: 0.9s;

  &.active {
    top: 98%;
    transform: translateY(-98%);
    opacity: 1;
    visibility: visible;
  }
  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: $transition;

    &:last-child {
      opacity: 0;
      visibility: hidden;
      top: 60%;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: $main-color;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }
  &:hover,
  &:focus {
    color: $white-color;

    &::before {
      opacity: 1;
      visibility: visible;
    }
    i {
      &:first-child {
        opacity: 0;
        top: 0;
        visibility: hidden;
      }
      &:last-child {
        opacity: 1;
        visibility: visible;
        top: 50%;
      }
    }
  }
}
