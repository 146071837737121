$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 576px;
$value_two: 767px;
$value_three: 768px;
$value_four: 991px;
$value_five: 992px;
$value_six: 1199px;
$value_seven: 1550px;

/* Max width: 767px */
@media only #{$media} and ($feature_max : $value_two) {
    body {
        font-size: 15px;
    }
    p {
        font-size: 15px;
    }
    .ptb-100 {
        padding: {
            top: 60px;
            bottom: 60px;
        };
    }
    .pb-100 {
        padding-bottom: 60px;
    }
    .pb-70 {
        padding-bottom: 30px;
    }
    .pt-100 {
        padding-top: 60px;
    }

    .section-title {
        text-align: center;
        max-width: 100%;
        margin: {
            left: 0;
            right: 0;
            bottom: 35px;
        };
        .sub-title {
            font-size: 16px;
        }
        h2 {
            font-size: 26px;
        }
        p {
            font-size: 15px;
            max-width: 100%;
            margin: {
                left: 0;
                right: 0;
            };
        }
    }
    .default-btn {
        font-size: 14px;
        padding: {
            left: 28px;
            right: 28px;
            top: 14px;
            bottom: 14px;
        };
    }
    .optional-btn {
        font-size: 14px;
        top: -2px;
        padding: {
            left: 25px;
            right: 25px;
            top: 9px;
            bottom: 9px;
        };
    }

    .home-area {
        &::before {
            display: none;
        }
    }
    .main-banner {
        height: 100%;
        padding: {
            top: 170px;
            bottom: 170px;
        };
    }
    .main-banner-content {
        max-width: 100%;
        margin: {
            left: 0;
            right: 0;
            top: 0;
        };
        .sub-title {
            letter-spacing: 1px;
            font-size: 15px;
            text-transform: capitalize;
        }
        h1 {
            font-size: 30px;
        }
        .btn-box {
            margin-top: 23px;

            .default-btn, .optional-btn {
                display: block;
                width: 100%;
                font-size: 13px;
                margin: 0;
            }
            .optional-btn {
                margin-top: 12px;
            }
        }
        p {
            font-size: 15px;
            margin-top: 15px;
        }
        form {
            max-width: 100%;
            margin: {
                left: 0;
                right: 0;
                top: 20px;
            };
            .form-group {
                .input-location {
                    width: 100%;
                    font-size: 15px;
                }
            }
            .default-btn {
                height: 53px;
                line-height: 53px;
                position: relative;
                display: block;
                width: 100%;
                margin-top: 12px;
            }
        }
    }
    .home-slides {
        .swiper-button-next, .swiper-button-prev {
            top: auto;
            bottom: 75px;
            &::after {
                font-size: 30px;
            }
        }
    }
    .banner-footer {
        display: none;
    }
    .home-section {
        .main-banner {
            padding-bottom: 155px;
        }
    }
    .banner-section {
        height: 100%;
        padding: {
            top: 180px;
            bottom: 120px;
        };
        .main-banner-content {
            margin-top: 0;
        }
    }

    .services-boxes-area {
        margin: {
            top: -55px;
            bottom: -30px;
        };
    }
    .single-box {
        margin-bottom: 30px;

        img {
            width: 100%;
        }
        .content {
            padding: 50px 25px 35px;

            h3 {
                font-size: 20px;
            }
        }
        .hover-content {
            padding: 65px 25px 35px;

            h3 {
                font-size: 20px;
            }
        }
    }
    .services-box {
        .services-content {
            padding: 25px 20px;

            h3 {
                font-size: 20px;
            }
        }
    }

    .about-section {
        .container-fluid {
            padding: {
                left: 15px;
                right: 15px;
            }
        }
    }
    .about-image {
        img {
            width: 100%;
        }
        .img1 {
            padding-top: 0;

            &::before {
                display: none;
            }
        }
        .img2 {
            position: relative;
            left: 0;
            top: 0;
            border-width: 0;
            margin-top: 15px;
        }
        .img3 {
            position: relative;
            left: 0;
            bottom: 0;
            border-width: 0;
            margin-top: 15px;
        }
    }
    .about-content {
        max-width: 100%;
        padding-left: 0;
        margin-top: 30px;
        text-align: center;

        .sub-title {
            margin-bottom: 5px;
            font-size: 15px;
        }
        h2 {
            font-size: 26px;
        }
        .features-list {
            li {
                padding-left: 15px;
                -ms-flex: 0 0 100%;
                -webkit-box-flex: 0;
                flex: 0 0 100%;
                max-width: 100%;

                .icon {
                    font-size: 33px;
                    position: relative;
                    left: 0;
                    top: 0;
                    margin-bottom: 3px;
                }
                span {
                    font-size: 16px;
                }
            }
        }
        .read-more-btn {
            font-size: 15px;
        }
    }
    .about-title {
        text-align: center;
        padding-right: 0;
        border-right: 0;

        span {
            font-size: 16px;
        }
        h2 {
            font-size: 26px;
        }
    }
    .about-text {
        text-align: center;
        padding-left: 0;
        margin-top: 12px;

        .read-more-btn {
            margin-top: 15px;
            font-size: 15px;
        }
    }

    .video-area {
        &::before {
            height: 20%;
        }
    }
    .video-content {
        h2 {
            font-size: 26px;
            line-height: 1.3;
        }
    }
    .video-box {
        .play-video {
            left: 50%;
            top: 65%;
            transform: translateX(-50%) translateY(-65%);

            .video-btn {
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 20px;
            }
            span {
                margin-top: 15px;
            }
        }
    }

    .shorting-menu {
        margin-bottom: 30px;

        .filter {
            margin: 5px;
            padding: 8px 15px;
            font-size: 14px;
            margin: {
                left: 1px;
                right: 1px;
            };
        }
    }
    .single-gallery-box {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .single-services-box {
        padding: 20px 15px;
        .icon {
            font-size: 40px;
            margin-top: -10px;

            .icon-bg {
                img {
                    max-height: 100%;
                }
            }
        }
        h3 {
            font-size: 18px;
        }
    }

    .partner-title {
        h2 {
            margin-bottom: 30px;
            font-size: 16px;
        }
    }

    .why-choose-us-area {
        padding-bottom: 0;
    }
    .why-choose-us-image {
        background-image: unset;
        height: auto;

        img {
            display: inline-block;
        }
    }
    .why-choose-us-content {
        top: 0;

        &::before {
            display: none;
        }
        .content {
            text-align: center;
            max-width: 100%;
            padding: {
                left: 15px;
                top: 40px;
                bottom: 60px;
                right: 15px;
            };
            .sub-title {
                font-size: 16px;
            }
            h2 {
                font-size: 26px;
            }
            p {
                font-size: 15px;
            }
            .features-list {
                text-align: left;

                li {
                    -ms-flex: 0 0 100%;
                    -webkit-box-flex: 0;
                    flex: 0 0 100%;
                    max-width: 100%;

                    span {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .benefits-image {
        margin: {
            top: 20px;
            bottom: 20px;
        };
    }

    .single-pricing-box {
        padding-bottom: 35px;

        .pricing-header {
            padding: {
                top: 40px;
                bottom: 40px;
            };
            h3 {
                font-size: 22px;
            }
            &::after {
                display: none;
            }
        }
        .price {
            font-size: 35px;

            sub {
                font-size: 15px;
            }
        }
        .pricing-features-list {
            margin-top: 20px;
            padding: {
                left: 25px;
                right: 25px;
            };
            li {
                font-size: 15px;
            }
        }
    }

    .join-content {
        max-width: 100%;
        margin: {
            left: 0;
            right: 0;
        };
        h2 {
            font-size: 28px;
        }
    }

    .single-feedback-item {
        padding-top: 55px;

        &::before {
            margin-top: -20px;
            font-size: 50px;
        }
        p {
            font-size: 18px;
            line-height: 1.7;
            max-width: 100%;
            margin: {
                left: 0;
                right: 0;
            };
            &::before {
                left: -3px;
                top: 9px;
            }
            &::after {
                bottom: -10px;
            }
        }
        .client {
            margin-top: 20px;
        }
    }

    .mission-text {
        padding: {
            left: 10px;
            right: 10px;
            bottom: 30px;
            top: 30px;
        };
        .icon {
            font-size: 45px;
        }
        h3 {
            font-size: 20px;
        }
    }
    .mission-image {
        background-image: unset;
        height: auto;

        img {
            display: inline-block;
        }
    }

    .team-area {
        padding-bottom: 30px;
    }
    .single-team-box {
        .content {
            padding: 20px;
            h3 {
                font-size: 17px;
            }
            span {
                margin-top: 5px;
                font-size: 14px;
            }
        }
    }

    .place-content {
        padding: {
            top: 60px;
            bottom: 60px;
            left: 15px;
            right: 15px;
        };
        .sub-title {
            font-size: 15px;
        }
        h2 {
            font-size: 26px;
        }
        p {
            font-size: 14px;
            max-width: 100%;
            margin: {
                left: 0;
                right: 0;
            };
        }
        .features-list {
            li {
                font-size: 14px;
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                padding: {
                    top: 20px;
                };
                .icon {
                    font-size: 35px;
                }
                span {
                    font-size: 16px;
                    margin-bottom: 6px;
                }
            }
        }
    }
    .single-place-image {
        height: auto;
        background-image: unset !important;

        img {
            display: inline-block !important;
        }
    }
    .place-image-slides {
        height: auto;
    }

    .single-blog-post {
        .post-image {
            .date {
                padding: 10px 20px 0;
            }
        }
        .post-content {
            padding: 20px;

            h3 {
                font-size: 20px;
                line-height: 1.4;
            }
            .default-btn {
                padding: {
                    top: 13px;
                    bottom: 13px;
                };
            }
        }
    }
    .blog-notes {
        margin-top: 5px;

        p {
            line-height: 1.8;
        }
    }

    .subscribe-area {
        margin-bottom: 40px;
        padding-bottom: 40px;
    }
    .subscribe-content {
        text-align: center;
        margin-bottom: 25px;

        h2 {
            font-size: 26px;
        }
    }
    .subscribe-form {
        padding-left: 0;
        text-align: center;

        form {
            button {
                position: relative;
                right: 0;
                top: 0;
                margin-top: 10px;
                display: block;
                width: 100%;
            }
        }
    }

    .page-title-area {
        height: 100%;
        padding: {
            top: 140px;
            bottom: 60px;
        };
    }
    .page-title-content {
        margin-top: 0;

        h2 {
            font-size: 30px;
        }
        ul {
            margin-top: 10px;

            li {
                font-size: 15px;
            }
        }
    }

    .tab {
        .tabs {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            width: auto;
            margin: {
                left: -5px;
                right: -5px;
                bottom: 30px;
            };
            li {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
                padding: {
                    left: 5px;
                    right: 5px;
                    bottom: 10px;
                };
                &::before {
                    display: none;
                }
                font-size: 15px;
            }
        }
        .tab_content {
            padding: 30px;

            .tabs_item {
                .content {
                    order: 1;
                    margin-bottom: 30px;
                }
                .image {
                    order: 2;
                }
                .tabs_item_content {
                    .carousel {
                        height: 60vw;
                    }
                    h3 {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .single-event-box {
        margin-bottom: 30px;

        .event-image {
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            order: 2;

            .image {
                background-image: unset;
                height: auto;

                img {
                    display: inline-block;
                }
            }
        }
        .event-content {
            order: 1;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;

            .content {
                padding: 30px 25px;

                h3 {
                    font-size: 20px;
                }
                ul {
                    li {
                        -ms-flex: 0 0 100%;
                        -webkit-box-flex: 0;
                        flex: 0 0 100%;
                        max-width: 100%;
                        margin-bottom: 15px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    .services-details-overview {
        margin-bottom: 30px;

        .services-details-desc {
            -ms-flex: 0 0 100%;
            -webkit-box-flex: 0;
            flex: 0 0 100%;
            max-width: 100%;
            order: 1;
            margin-bottom: 30px;

            h3 {
                font-size: 21px;
                margin-bottom: 12px;
            }
            .features-text {
                h4 {
                    font-size: 15px;
                    margin-bottom: 12px;
                }
            }
            .services-details-accordion {
                .accordion {
                    .accordion-title {
                        font-size: 15px;
                    }
                }
            }
        }
        .services-details-image {
            -ms-flex: 0 0 100%;
            -webkit-box-flex: 0;
            flex: 0 0 100%;
            max-width: 100%;
            order: 2;
        }
    }

    .error-area {
        height: 100%;
        padding: {
            top: 120px;
            bottom: 120px;
        };
    }
    .error-content {
        h3 {
            font-size: 25px;
        }
    }

    .pagination-area {
        margin-top: 10px;
        .page-numbers {
            width: 40px;
            height: 40px;
            margin: 0 2px;
            line-height: 41px;
        }
    }

    .widget-area {
        margin-top: 40px;
        padding: 0;

        .widget {
            .widget-title {
                font-size: 19px;
            }
        }
        .widget_zash_posts_thumb {
            .item {
                .info {
                    .title {
                        font-size: 15px;
                    }
                }
            }
        }
        .widget_categories {
            ul {
                li {
                    font: {
                        size: 15px;
                        weight: 500;
                    };
                }
            }
        }
        .widget_recent_comments {
            ul {
                li {
                    font: {
                        size: 15px;
                        weight: 500;
                    };
                }
            }
        }
        .widget_recent_entries {
            ul {
                li {
                    font: {
                        size: 15px;
                        weight: 500;
                    };
                }
            }
        }
        .widget_archive {
            ul {
                li {
                    font: {
                        size: 15px;
                        weight: 500;
                    };
                }
            }
        }
        .widget_meta {
            ul {
                li {
                    font: {
                        size: 15px;
                        weight: 500;
                    };
                }
            }
        }
    }

    .blog-details-desc {
        .article-content {
            margin-top: 25px;

            .entry-meta {
                margin-bottom: -10px;

                ul {
                    li {
                        display: block;
                        margin-right: 0;
                        font-size: 15px;
                        margin-bottom: 5px;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
            h3 {
                font-size: 20px;
                line-height: 1.4;
                margin: {
                    bottom: 14px;
                    top: 20px;
                }
            }
        }
        .article-footer {
            text-align: center;

            .article-tags {
                -ms-flex: 0 0 100%;
                -webkit-box-flex: 0;
                flex: 0 0 100%;
                max-width: 100%;
            }
            .article-share {
                -ms-flex: 0 0 100%;
                -webkit-box-flex: 0;
                flex: 0 0 100%;
                max-width: 100%;
                margin-top: 15px;

                .social {
                    text-align: center;
                }
            }
        }
    }
    blockquote, .blockquote {
        padding: 30px !important;

        p {
            font-size: 17px !important;
        }
    }
    .comments-area {
        padding: 20px;

        .comment-author {
            .avatar {
                left: 0;
                position: relative;
                display: block;
                margin-bottom: 10px;
            }
        }
        .comment-body {
            padding-left: 0;
        }
        .comments-title {
            margin-bottom: 20px;
            font-size: 20px;

            &::before {
                left: -20px;
            }
        }
        .comment-author {
            margin-bottom: 0.3em;
        }
        .comment-metadata {
            font-size: 13px;
        }
        .comment-respond {
            .comment-reply-title {
                font-size: 20px;

                &::before {
                    left: -20px;
                }
            }
            label {
                font-size: 15px;
            }
            .comment-form-author {
                width: 100%;
                padding-right: 0;
            }
            .comment-form-email {
                width: 100%;
                padding-left: 0;
            }
            .form-submit {
                input {
                    font-size: 15px;
                    padding: 12px 25px 10px;
                }
            }
        }
    }

    .contact-area {
        .section-title {
            margin-bottom: 30px;
        }
    }
    .contact-info {
        padding-right: 0;
        margin-bottom: 45px;

        .contact-list {
            padding-right: 0;

            li {
                margin-bottom: 20px;
                padding-left: 78px;

                .icon {
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    font-size: 20px;
                    top: 5px;
                    transform: unset;
                }
                span {
                    font-size: 18px;
                }
            }
        }
        .social {
            padding-left: 80px;

            li {
                a {
                    width: 45px;
                    font-size: 15px;
                    height: 45px;
                    line-height: 45px;
                }
            }
        }
    }

    .event-details-area {
        .widget-area {
            padding-left: 0;
            margin-top: 30px;
        }
    }
    .event-details {
        .event-details-header {
            .back-all-event {
                font-size: 13px;
                margin-bottom: 13px;
            }
            h3 {
                font-size: 21px;
            }
            .event-info-meta {
                li {
                    display: block;
                    margin: {
                        right: 0;
                        bottom: 5px;
                    };
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .footer-area {
        padding-top: 60px;
    }
    .single-footer-widget {
        p {
            font-size: 14px;
        }
        h3 {
            font-size: 20px;
        }
        .footer-quick-links {
            li {
                padding-top: 10px;

                a {
                    font-size: 13px;
                }
            }
        }
        .footer-contact-info {
            li {
                font-size: 14px;
            }
        }
    }
    .copyright-area {
        margin-top: 30px;
        text-align: center;
        padding: {
            top: 20px;
            bottom: 20px
        };
        p {
            font-size: 14px;
        }
        ul {
            text-align: center;
            margin-top: 15px;

            li {
                font-size: 14px;
            }
        }
    }

    .tuam-nav {
        .navbar {
            padding-right: 0;
            padding-left: 0;

            .navbar-nav {
                margin-top: 20px;
                max-height: 60vh;
                overflow-y: scroll;
                border-top: 1px solid #eee;
                padding-top: 10px;
                /* width */
                &::-webkit-scrollbar {
                    width: 10px;
                }
                /* Track */
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                .nav-item {
                    a {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        i {
                            display: none;
                        }
                    }
                    &:first-child {
                        a {
                            margin-left: 13px;
                        }
                    }
                    .dropdown-menu {
                        left: 15px;
                        position: relative;
                        top: 0 !important;
                        width: 90%;
                        display: block;
                        opacity: 1;
                        visibility: visible;
                        margin-top: 0;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        margin-bottom: 10px;

                        li {
                            .dropdown-menu {
                                left: 15px;
                                position: relative;
                                top: 0 !important;
                                width: 90%;
                                display: block;
                                opacity: 1;
                                visibility: visible;
                                margin-top: 0;
                                padding-top: 10px;
                                padding-bottom: 10px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
            .others-option {
                margin: 0;
                border-top: 1px solid #eee;
                padding-top: 15px;
            }
        }
    }
    .modal-video-inner {
        padding: 0 15px;
    }
    .go-top {
        width: 35px;
        height: 35px;
        line-height: 35px;
        border-radius: 50%;
        i {
            border-radius: 50%;
        }
    }

    .navbar-area.navbar-style-two {
        .tuam-nav {
            .navbar {
                .navbar-brand {
                    .white-logo {
                        display: none;
                    }
                    .logo {
                        display: block !important;
                    }
                }
                .navbar-nav {
                    .nav-item {
                        a {
                            color: #524552;
                        }
                    }
                }
                .others-option {
                    .call-us {
                        color: #333;
                    }
                    .default-btn {
                        margin-left: 15px;
                        background-color: #000;
                        padding: 10px 15px;
                    }
                }
            }
        }
    }
    .navbar-area.navbar-style-two.is-sticky {
        .tuam-nav {
            .navbar {
                background-color: transparent;
            }
        }
    }
    .single-footer-widget {
        .footer-quick-links {
            margin-right: 0;
        }
    }
}

/* Min width: 576px to Max width: 767px */
@media only #{$media} and ($feature_min : $value_one) and ($feature_max : $value_two) {
    .why-choose-us-content {
        .content {
            .features-list {
                li {
                    -ms-flex: 0 0 50%;
                    flex: 0 0 50%;
                    max-width: 50%;
                }
            }
        }
    }

    .main-banner-content {
        .btn-box {
            .optional-btn, .default-btn {
                display: inline-block;
                width: auto;
                margin: 0 10px;
            }
        }
    }

    .single-gallery-box {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .about-section {
        .container-fluid {
            max-width: 540px;
        }
    }
    .about-image {
        img {
            width: auto;
        }
        .img1 {
            padding-top: 25px;
            max-width: 80%;

            &::before {
                display: block;
            }
        }
        .img2 {
            position: absolute;
            top: 55px;
            border-width: 3px;
            margin-top: 0;
            max-width: 60%;
        }
        .img3 {
            position: absolute;
            bottom: 55px;
            border-width: 3px;
            margin-top: 0;
            max-width: 60%;
        }
    }

    .mission-text {
        padding: {
            left: 60px;
            right: 60px;
        };
    }

    .event-details {
        .event-details-header {
            .event-info-meta {
                li {
                    display: inline-block;
                    margin: {
                        right: 10px;
                        bottom: 0;
                    };
                }
            }
        }
    }

    .place-content {
        .features-list {
            li {
                -ms-flex: 0 0 50%;
                -webkit-box-flex: 0;
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }

    .tab {
        .tabs {
            li {
                -ms-flex: 0 0 33.333333%;
                -webkit-box-flex: 0;
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
                font-size: 16px;
            }
        }
        .tab {
            .tab_content {
                .tabs_item {
                    .tabs_item_content {
                        h3 {
                            font-size: 21px;
                        }
                    }
                }
            }
        }
    }

    .about-content {
        .features-list {
            li {
                -ms-flex: 0 0 50%;
                -webkit-box-flex: 0;
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }

    .subscribe-form {
        form {
            button {
                position: absolute;
                right: 5px;
                top: 5px;
                margin-top: 0;
                display: inline-block;
                width: auto;
            }
        }
    }
    .copyright-area {
        text-align: left;
        ul {
            text-align: right;
            margin-top: 0;
        }
    }
    .subscribe-form form {
        form {
            .input-newsletter {
                height: 60px;
            }
        }
    }
}

/* Min width: 768px to Max width: 991px */
@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {
    .default-btn {
        font-size: 15px;
    }
    .optional-btn {
        font-size: 15px;
    }
    .section-title {
        max-width: 610px;

        h2 {
            font-size: 36px;
        }
    }

    .pb-100 {
        padding-bottom: 70px;
    }
    .pb-70 {
        padding-bottom: 40px;
    }
    .pt-100 {
        padding-top: 70px;
    }

    .main-banner {
        height: 100%;
        padding: {
            top: 250px;
            bottom: 320px;
        };
    }
    .main-banner-content {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        h1 {
            font-size: 40px;
        }
    }
    .home-section {
        .banner-footer {
            .banner-video {
                display: none;
            }
            .banner-contact-info {
                border-radius: 0;

                ul {
                    max-width: 100%;
                }
            }
        }
    }
    .banner-section {
        height: 100%;
        padding: {
            top: 250px;
            bottom: 190px;
        };
        .main-banner-content {
            margin-top: 0;
        }
    }

    .about-title {
        border-right: none;
        padding-right: 0;
        text-align: center;
        max-width: 710px;
        margin: {
            left: auto;
            right: auto;
        };
        h2 {
            font-size: 36px;
        }
    }
    .about-text {
        padding-left: 0;
        text-align: center;
        max-width: 710px;
        margin: {
            left: auto;
            top: 15px;
            right: auto;
        };
    }
    .about-content {
        max-width: 100%;
        text-align: center;
        margin-top: 35px;
        padding: {
            left: 25px;
            right: 25px;
        };
        .features-list {
            text-align: left;
        }
    }

    .place-content {
        .features-list {
            li {
                span {
                    font-size: 20px;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .services-boxes-area {
        margin-bottom: -30px;
    }
    .single-box {
        margin-bottom: 30px;
    }

    .why-choose-us-image {
        background-image: unset;
        height: auto;

        img {
            display: inline-block;
            width: 100%;
        }
    }
    .why-choose-us-content {
        .content {
            max-width: 100%;
            padding-left: 30px;
            padding-top: 70px;
            padding-right: 30px;
            padding-bottom: 70px;
            h2 {
                font-size: 36px;
            }
        }
    }
    .shorting-menu {
        margin-bottom: 35px;

        .filter {
            margin-bottom: 5px;
        }
    }
    .single-gallery-box {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .benefits-section {
        .row {
            .col-lg-6 {
                display: none;
            }
        }
    }

    .single-feedback-item {
        p {
            max-width: 670px;
            font-size: 25px;
        }
    }

    .our-mission-area {
        .row {
            .col-lg-3 {
                &:nth-child(1) {
                    order: 2;
                }
                &:nth-child(2) {
                    order: 1;
                }
                &:nth-child(3) {
                    order: 3;
                }
                &:nth-child(4) {
                    order: 4;
                }
            }
        }
    }

    .single-place-image {
        height: auto;
        background-image: unset !important;

        img {
            display: inline-block !important;
        }
    }
    .place-image-slides {
        height: auto;
    }

    .page-title-area {
        height: 385px;
    }
    .page-title-content {
        margin-top: 65px;
    }

    .tab {
        .tabs {
            li {
                padding: 15px 33px;
                font-size: 15px;
            }
        }
        .tabs_item {
            .content {
                order: 1;
                margin-bottom: 30px;
            }
            .image {
                order: 2;
            }
        }
    }

    .about-content {
        max-width: 710px;
        margin: {
            left: auto;
            right: auto;
        };
        h2 {
            font-size: 36px;
        }
    }

    .place-content {
        h2 {
            font-size: 36px;
        }
    }

    .services-details-overview {
        margin-bottom: 40px;

        .services-details-desc {
            -ms-flex: 0 0 100%;
            -webkit-box-flex: 0;
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 40px;
            order: 1;
        }
        .services-details-image {
            -ms-flex: 0 0 100%;
            -webkit-box-flex: 0;
            flex: 0 0 100%;
            max-width: 100%;
            order: 2;
        }
    }

    .error-area {
        height: 100%;
        padding: {
            top: 120px;
            bottom: 120px;
        };
    }

    .widget-area {
        margin-top: 40px;
        padding: 0;
    }

    .contact-info {
        margin-bottom: 45px;
    }

    .event-details {
        .event-details-header {
            .back-all-event {
                margin-bottom: 14px;
                font-size: 14px;
            }
        }
    }

    .event-details-area {
        .widget-area {
            padding-left: 0;
            margin-top: 30px;
        }
    }

    .subscribe-content {
        text-align: center;
        margin-bottom: 25px;
    }
    .subscribe-form {
        padding-left: 0;
        text-align: center;
    }
    .tuam-nav {
        .navbar {
            padding: 0;
            .navbar-nav {
                .nav-item {
                    a {
                        padding-top: 15px;
                        padding-bottom: 15px;
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                    &:nth-last-child(1), &:nth-last-child(2) {
                        .dropdown-menu {
                            left: auto;
                            right: 0;
                        }
                    }
                }
            }
            .others-option {
                display: none;
            }
        }
    }
    .single-blog-post {
        .post-content {
            h3 {
                font-size: 20px;
            }
        }
    }

    .ptb-100 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .team-area {
        padding-bottom: 50px;
    }
    .place-content {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .copyright-area {
        p {
            font-size: 14px;
        }
        ul {
            li {
                font-size: 15px;
            }
        }
    }
    .modal-video-inner {
        padding: 0 15px;
    }
    .video-content {
        h2 {
            font-size: 36px;
        }
    }
    .single-services-box {
        h3 {
            font-size: 20px;
        }
    }
    .join-content {
        h2 {
            font-size: 36px;
        }
    }
    .navbar-area.navbar-style-two {
        .tuam-nav {
            .navbar {
                .navbar-brand {
                    .white-logo {
                        display: none;
                    }
                    .logo {
                        display: block !important;
                    }
                }
                .navbar-nav {
                    .nav-item {
                        a {
                            color: #524552;
                            margin-left: 8px;
                            margin-right: 7px;
                            font-size: 14px;
                        }
                    }
                }
                .others-option {
                    margin-left: 10px;
                    .call-us {
                        display: none;
                    }
                    .default-btn {
                        margin-left: 0;
                        background-color: #000;
                        padding: 10px 8px;
                        font-size: 9px;
                    }
                }
            }
        }
    }
    .home-section {
        .banner-footer {
            .banner-contact-info {
                ul {
                    li {
                        padding-left: 75px;
                        i {
                            width: 45px;
                            height: 45px;
                            line-height: 45px;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .navbar-area.navbar-style-two.is-sticky {
        .tuam-nav {
            .navbar {
                background-color: transparent;
            }
        }
    }

    .page-title-content {
        h2 {
            font-size: 40px;
        }
    }
    .single-event-box {
        .event-content {
            .content {
                h3 {
                    font-size: 20px;
                }
            }
        }
    }
    .single-services-box {
        padding: 30px 20px;
    }
    .single-team-box {
        .content {
            h3 {
                font-size: 20px;
            }
        }
    }
    .pagination-area {
        margin-top: 20px;
    }
}

/* Min width: 992px to Max width: 1199px */
@media only #{$media} and ($feature_min : $value_five) and ($feature_max : $value_six) {
    .tuam-nav {
        .navbar {
            .others-option {
                .call-us {
                    padding-left: 0;

                    .icon {
                        display: none;
                    }
                }
                .default-btn {
                    display: none;
                }
            }
        }
    }

    .home-section {
        .banner-footer {
            .banner-contact-info {
                padding: 25px;

                ul {
                    max-width: 100%;

                    li {
                        padding-left: 15px;

                        i {
                            display: none;
                        }
                        span {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }

    .about-image {
        .img2, .img3 {
            max-width: 70%;
        }
    }

    .place-content {
        .features-list {
            li {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }

    .why-choose-us-content {
        .content {
            max-width: 100%;
            padding: {
                left: 25px;
                right: 15px;
            };
        }
    }

    .mission-text {
        padding: {
            left: 15px;
            right: 15px;
        };
    }

    .main-banner-content {
        max-width: 870px;
        h1 {
            font-size: 50px;
        }
    }
    .single-blog-post {
        .post-content {
            h3 {
                font-size: 19px;
            }
        }
    }
    .about-title {
        h2 {
            font-size: 35px;
        }
    }
    .why-choose-us-content {
        .content {
            h2 {
                font-size: 35px;
            }
        }
    }
    .section-title {
        h2 {
            font-size: 36px;
        }
    }
    .single-pricing-box {
        .pricing-features-list {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .mission-text {
        h3 {
            font-size: 20px;
        }
    }
    .place-content {
        h2 {
            font-size: 35px;
        }
    }
    .place-content {
        .features-list {
            li {
                span {
                    font-size: 20px;
                }
            }
        }
    }
    .about-content {
        h2 {
            font-size: 35px;
        }
    }

    .about-content {
        .features-list {
            li {
                padding-left: 65px;
                span {
                    font-size: 15px;
                }
            }
        }
    }
    .video-content {
        h2 {
            font-size: 36px;
        }
    }

    single-services-box {
        padding: 30px 20px;
        h3 {
            font-size: 20px;
        }
        .read-more-btn {
            font-size: 15px;
        }
    }
    .subscribe-content {
        h2 {
            font-size: 30px;
        }
    }
    .single-services-box {
        h3 {
            font-size: 20px;
        }
    }
    .event-details-area {
        .widget-area {
            padding-left: 10px;
        }
    }
    .single-services-box {
        padding: 30px 20px;
    }
    .single-team-box {
        .content {
            h3 {
                font-size: 20px;
            }
        }
    }
    .widget-area {
        padding-left: 0;
    }
    .widget-area {
        .widget_zash_posts_thumb {
            .item {
                .info {
                    .title {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .pagination-area {
        margin-top: 20px;
    }
}

@media only screen and (min-width : 1200px) and (max-width : 1399px) {
    .navbar-area.navbar-style-two .tuam-nav .navbar .others-option .default-btn {
        margin-left: 20px;
        padding: 15px 20px;
    }
}

/* Min width: 1550px */
@media only #{$media} and ($feature_min : $value_seven) {
    .main-banner {
        height: 100vh;
    }
}
