/*
Flaticon icon font: Flaticon
*/
@font-face {
  font-family: 'Flaticon';
  src: url('./fonts/Flaticon.eot');
  src: url('./fonts/Flaticon.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Flaticon.woff2') format('woff2'),
    url('./fonts/Flaticon.woff') format('woff'),
    url('./fonts/Flaticon.ttf') format('truetype'),
    url('./fonts/Flaticon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    src: url('./fonts/Flaticon.svg#Flaticon') format('svg');
  }
}
[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
  font-family: Flaticon;
  font-style: normal;
}

.flaticon-call:before {
  content: '\f100';
}
.flaticon-play-button:before {
  content: '\f101';
}
.flaticon-next:before {
  content: '\f102';
}
.flaticon-university:before {
  content: '\f103';
}
.flaticon-work:before {
  content: '\f104';
}
.flaticon-room:before {
  content: '\f105';
}
.flaticon-layers:before {
  content: '\f106';
}
.flaticon-security:before {
  content: '\f107';
}
.flaticon-wifi:before {
  content: '\f108';
}
.flaticon-location:before {
  content: '\f109';
}
.flaticon-taxi:before {
  content: '\f10a';
}
.flaticon-calendar:before {
  content: '\f10b';
}
.flaticon-check-mark:before {
  content: '\f10c';
}
.flaticon-right-chevron:before {
  content: '\f10d';
}
.flaticon-left-quote:before {
  content: '\f10e';
}
.flaticon-left-chevron:before {
  content: '\f10f';
}
.flaticon-target:before {
  content: '\f110';
}
.flaticon-award:before {
  content: '\f111';
}
.flaticon-parking:before {
  content: '\f112';
}
.flaticon-breakfast:before {
  content: '\f113';
}
.flaticon-shop:before {
  content: '\f114';
}
.flaticon-facebook:before {
  content: '\f115';
}
.flaticon-twitter:before {
  content: '\f116';
}
.flaticon-linkedin:before {
  content: '\f117';
}
.flaticon-instagram:before {
  content: '\f118';
}
.flaticon-cross-out:before {
  content: '\f119';
}
.flaticon-expand:before {
  content: '\f11a';
}
.flaticon-play-button-1:before {
  content: '\f11b';
}
.flaticon-down-arrow:before {
  content: '\f11c';
}
.flaticon-email:before {
  content: '\f11d';
}
